import React, { Component } from 'react'
import CTAButton from "../../components/CTAButton"
import { heroImage } from "../../assets/images"

class Hero extends Component {
  render() {
    return (
      <div className="px-4 max-w-[1440px] mx-auto flex flex-col xl:flex-row items-center gap-7 py-16">
        <div className="text-center xl:text-left basis-1/2">
          <h1 className="text-3xl sm:text-4xl 2xl:text-5xl font-semibold mb-5 !leading-[1.2]">Platinum Level Financial Management for Music's Biggest Managers.</h1>
          <p className="text-xl mb-12">Streamline your finances, maximize artist earnings, and scale your management business with our powerful, all-in-one platform.</p>
          <CTAButton />
        </div>
        <div className="w-full xl:basis-1/2">
          <div className="bg-neutral-200/50 dark:bg-white/10 p-2 rounded-lg border border-neutral-300 dark:border-neutral-50/10 lg:max-w-[690px] mx-auto">
            <div className="bg-[url('./assets/images/hero-image.png')] bg-cover bg-x-center bg-y-top bg-no-repeat w-full h-[350px] rounded-lg"></div>
            <img src={heroImage} alt="Event Ledger" className="hidden" />
          </div>
        </div>
      </div>
    )
  }
}

export default Hero
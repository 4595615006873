import React, { Component } from 'react'
import SectionHeading from "../../components/SectionHeading"
import CTAButton from "../../components/CTAButton"
import StarRating from "../../components/StarRating"

class SectionCTA extends Component {
  render() {
    return (
      <div className="max-w-[1440px] mx-auto px-4 py-12">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="basis-1/2">
            <img src="https://placehold.co/800x600" alt="" className="rounded-lg" />
          </div>
          <div className="flex flex-col gap-8 basis-1/2">
            <SectionHeading
              title="Financial Management for Music's Biggest Stars"
              description="Start your free trial and experience the future of music business accounting and  see how Event Ledger can amplify your financial success."
              left
            />
            <div><CTAButton /></div>
            <div className="flex items-center gap-4 mt-4">
              <div>
                <img src="https://i.pravatar.cc/75?img=35" alt="" className="rounded-xl" />
              </div>
              <div className="flex-1 flex flex-col gap-2">
                <StarRating />
                <p className="italic mt-1">"Neque et semper id ac integer sagittis diam. Diam leo vitae placerat turpis lobortis urna pharetra at. 
                Morbi ex tortor, efficitur vitae lectus ac, porta semper orci. Nulla tristique varius tempor"</p>
                <span className="font-semibold">Alena Culhane</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SectionCTA
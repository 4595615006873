import React, { Component } from 'react';
import AppRoutes from "./routes";
import createHistory from 'history/createBrowserHistory'

const history = createHistory()

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <AppRoutes history={history} />
      </React.Fragment>
    )
  }
}

import React, { Component } from 'react'
import { ReactComponent as IconFileScan } from "../../assets/icons/file-scan.svg"
import { ReactComponent as IconFileSpreadsheet } from "../../assets/icons/file-spreadsheet.svg"
import { ReactComponent as IconRightArrow } from "../../assets/icons/right-arrow.svg"
import { ReactComponent as IconNetwork } from "../../assets/icons/network.svg"
import { ReactComponent as IconReceipt } from "../../assets/icons/receipt.svg"
import SectionHeading from "../../components/SectionHeading"

const data = [
  {
    title: "Ingestion - Scan and move all bills into system", icon: IconFileScan,
  },
  {
    title: "Coding the bills", icon: IconFileSpreadsheet,
  },
  {
    title: "Route the bills into multi-approval workflows", icon: IconNetwork,
  },
  {
    title: "Pay Accounts. Account reconciliation occurs automatically in Event Ledger", icon: IconReceipt,
  }
]

class Process extends Component {
  render () {
    return (
      <div className="max-w-[1440px] px-4 mx-auto py-8">
        <SectionHeading title="How It Works" description="Get started with Event Ledger in four simple steps" />
        <div className="flex flex-col lg:flex-row items-start kg:items-center gap-8 mt-8">
          {data.map((step, idx) => (
            <React.Fragment key={`step-${idx}`}>
              <div className="flex-1 flex items-start gap-4">
                <div>
                  <step.icon className="size-10 md:size-12" />
                </div>
                <div>
                  <h3 className="text-base md:text-lg mb-2"><span className="font-bold uppercase block">Step {idx + 1}:</span> {step.title}</h3>
                </div>
              </div>
              {idx < data.length - 1 && (
                <IconRightArrow className="hidden lg:block size-12 opacity-25" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    )
  }
}

export default Process
import React, { Component } from 'react'
import { ReactComponent as LogoMark } from '../assets/images/event-ledger-logo-mark-light.svg'
import { Link } from "react-router-dom/cjs/react-router-dom"

class SiteFooter extends Component {
  render() {
    return (
      <div className="py-8 bg-neutral-800 text-white dark:bg-neutral-900">
        <div className="max-w-[1440px] px-4 mx-auto">
          <div className="flex flex-col gap-4 justify-center md:flex-row md:items-center justify-between">
            <div className="text-center md:text-left">
              <div className="inline-block">
                <LogoMark />
              </div>
              <p className="mt-2">©EventLedger 2024. All rights reserved</p>
            </div>
            <div>
              <nav>
                <ul className="flex justify-center md:justify-end gap-8">
                  <li><a href="https://www.link-to-support.com">Support</a></li>
                  <li><Link to="/privacy">Privacy</Link></li>
                  <li><Link to="/terms">Terms</Link></li>
                </ul>
              </nav>
              <p className="mt-2 text-sm text-center md:text-right">Made with 🤍 in Nashville, TN</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SiteFooter
export default function StarRating ({ testimonialIdx = 0 }) {
  return (
  <div className="flex">
    {Array.apply(null, Array(5)).map((item,idx) => 
    <svg key={`start-${testimonialIdx}-${idx}`} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.42 2.86795C11.53 2.67911 11.6877 2.52242 11.8772 2.41352C12.0667 2.30462 12.2814 2.24731 12.5 2.24731C12.7185 2.24731 12.9333 2.30462 13.1228 2.41352C13.3123 2.52242 13.4699 2.67911 13.58 2.86795L16.375 7.66595L21.803 8.84195C22.0165 8.88835 22.2141 8.9899 22.3761 9.13648C22.5382 9.28307 22.659 9.46956 22.7264 9.67737C22.7939 9.88519 22.8058 10.1071 22.7608 10.3209C22.7158 10.5347 22.6155 10.733 22.47 10.8959L18.77 15.0369L19.33 20.562C19.3521 20.7795 19.3167 20.9991 19.2274 21.1987C19.1381 21.3983 18.998 21.571 18.8211 21.6995C18.6442 21.828 18.4367 21.9079 18.2192 21.9312C18.0018 21.9544 17.7821 21.9202 17.582 21.832L12.5 19.5919L7.41799 21.832C7.2179 21.9202 6.99818 21.9544 6.78074 21.9312C6.5633 21.9079 6.35577 21.828 6.17886 21.6995C6.00195 21.571 5.86185 21.3983 5.77255 21.1987C5.68326 20.9991 5.64789 20.7795 5.66999 20.562L6.22999 15.0369L2.52999 10.8969C2.38421 10.734 2.28374 10.5356 2.2386 10.3217C2.19346 10.1077 2.20523 9.88568 2.27273 9.6777C2.34023 9.46973 2.46111 9.2831 2.62329 9.13645C2.78547 8.98979 2.98329 8.88825 3.19699 8.84195L8.62499 7.66595L11.42 2.86795Z" fill="#F6980B"/>
    </svg>
    )}
  </div>
  )
}
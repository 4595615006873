import React, { Component } from 'react'
import { ReactComponent as Logo } from '../assets/images/event-ledger-logo-sm.svg'
import { Link } from "react-scroll";

const smooth_duration = 500

class TopNav extends Component {
  render() {
    const link_classes = 'underline underline-offset-8 decoration-2 decoration-transparent hover:underline-offset-4 hover:decoration-neutral-300 transition-all'

    return (
      <nav className="flex flex-wrap items-center py-2 relative">
        <div className="flex-1 flex justify-between items-center">
          <a href="/" className="flex text-lg font-semibold">
            <Logo />
          </a>
        </div>
        <label htmlFor="menu-toggle" className="cursor-pointer lg:hidden block">
          <svg
            className="fill-current text-gray-900"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <title>menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </label>
        <input className="hidden" type="checkbox" id="menu-toggle" />
        <div className="hidden lg:flex lg:items-center lg:w-auto w-full" id="menu">
          <nav>
            <ul className="flex flex-col gap-2 items-end p-3 mt-2 rounded shadow-lg absolute w-full left-0 bg-white 
                        lg:bg-transparent lg:flex-row lg:static lg:shadow-none lg:p-0 lg:items-center lg:gap-8"
            >
              <li>
                <Link to="features" smooth={true} duration={smooth_duration} className={link_classes}>FEATURES</Link>
              </li>
              <li>
                <Link to="pricing" smooth={true} duration={smooth_duration} className={link_classes}>PRICING</Link>
              </li>
              <li>
                <a href="https://www.linktoblog.com" target="_blank" rel="noreferrer" className={link_classes}>BLOG</a>
              </li>
              <li>
                <a href="https://app-gpv8.onrender.com/login" className="btn-md btn-secondary inline-block">LOGIN</a>
              </li>
            </ul>
          </nav>
        </div>
      </nav>
    )
  }
}

export default TopNav
import React, { Component } from 'react'
import TopNav from "./components/TopNav"
import SiteFooter from "./components/SiteFooter"
import { Route, Router, Switch } from "react-router-dom"
import Homepage from "./views/Homepage"

class PageTemplate extends Component {
  render() {
    const { children } = this.props

    return (
      <div>
        <header className="max-w-[1440px] px-4 mx-auto">
          <TopNav />
        </header>
        <main>
          {children}
        </main>
        <footer>
          <SiteFooter />
        </footer>
      </div>
    )
  }
}

const RouteUser = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <PageTemplate>
          <Component {...matchProps} />
        </PageTemplate>
      )}
    />
  )
}

class AppRoutes extends Component {
  render() {
    const { history } = this.props
    return (
      <Router history={history}>
        <Switch>
          <RouteUser component={Homepage} />
        </Switch>
      </Router>
    )
  }
}

export default AppRoutes
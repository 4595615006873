import React, { Component } from 'react'
import Hero from "./Hero"
import Benefits from "./Benefits"
import Features from "./Features"
import FAQ from "./FAQ"
import SectionCTA from "./SectionCTA"
import Pricing from "./Pricing"
import Process from "./Process"
class Homepage extends Component {
  render() {
    return (
      <>
        <Hero />
        <Benefits />
        <Features />
        <Process />
        <div className="max-w-[1440px] mx-auto px-4">
          <hr className="section-divider" />
        </div>
        <FAQ />
        <div className="max-w-[1440px] mx-auto px-4">
          <hr className="section-divider" />
        </div>
        <Pricing />
        <div className="max-w-[1440px] mx-auto px-4">
          <hr className="section-divider" />
        </div>
        <SectionCTA />
      </>
    )
  }
}

export default Homepage
import { Transition } from "@headlessui/react"
import React, { Component } from 'react'

const SlideInFadeIn = ({ children, show }) => (
  <Transition
    show={show}
    appear={true}
    enter="transition-all ease-in-out duration-500 delay-[200ms]"
    enterFrom="opacity-0 translate-x-6"
    enterTo="opacity-100 translate-x-0"
    leave="transition-all ease-in-out duration-500"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition>
)

const features = [
  {title: 'Digital Invoice Management', img_url: "https://placehold.co/1280x1000?text=Image+One", 
    description: `With Event Ledger, you can securely store all your invoices and important documents in the cloud. 
    Attach invoice images to transactions for seamless approvals and effortless audits.`
  },
  {title: 'One Cloud, Multiple Ledgers', img_url: "https://placehold.co/1280x1000?text=Image+Two", 
    description: `Event Ledger's cloud-based, multi-entity general ledger is designed specifically for music management 
    firms like yours. Our integrated accounting system eliminates double entry and consolidates all your financial data 
    into one powerful platform. Manage your artists' finances with unparalleled efficiency and accuracy.`,
  },
  {title: 'Control Every Dollar', img_url: "https://placehold.co/1280x1000?text=Image+Three", 
    description: `Event Ledger gives you complete control over your artists' finances with configurable approval workflows. 
    Set multiple levels of approval and integrate seamlessly with your bank to ensure authorized payments only.`,
  },
]

class Features extends Component {
  state = {
    active: 0
  }

  render() {
    return (
      <div className="py-28 bg-neutral-800 text-white dark:bg-neutral-900" id="features">
        <div className="max-w-[1440px] px-4 mx-auto flex flex-col xl:flex-row gap-20">
          <div className="basis-1/2">
            <div className="mb-5">
              <h2 className="text-5xl font-semibold mb-3">Features</h2>
              <p className="text-xl">Posuere egestas consectetur elementum sagittis. Neque et semper id ac integer sagittis diam.</p>
            </div>
            <div className="flex flex-col gap-4">
              {features.map((item, idx) => (
                <div
                  key={`feature-${idx}`}
                  className="px-5 py-3 border border-transparent rounded opacity-75 transition-all hover:opacity-100 
                  data-[active]:opacity-100 data-[active]:bg-neutral-600/25 data-[active]:border-neutral-700/50"
                  role="button"
                  onClick={() => this.setState({ active: idx })}
                  data-active={this.state.active === idx ? true : null}
                >
                  <h3 className="font-medium text-lg mb-2">{item.title}</h3>
                  <p className="text-gray-400/80">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="basis-1/2">
          {features.map((item, idx) => (
          this.state.active === idx &&
          <SlideInFadeIn show={true} key={`feature-${idx}`}>
            <img src={item.img_url} alt="" className="rounded-lg" />
          </SlideInFadeIn>
          ))}
          </div>
        </div>
      </div>
    )
  }
}

export default Features
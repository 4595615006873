import React, { Component } from 'react'
import SectionHeading from "../../components/SectionHeading"

class Pricing extends Component {
  render() {
    const pricing_plan_box_classes = 'flex flex-col justify-between px-8 py-6 rounded-lg bg-stone-100 shadow-lg max-w-[400px] border border-gray-300'

    return (
      <div className="max-w-[1440px] px-4 mx-auto py-12" id="pricing">
        <SectionHeading
          title="Enterprise Pricing"
          description="Price per seat, not per artist.  Unlock revenue growth without exponential costs. Our per-seat pricing scales with your success."
        />
        <div className="flex flex-col lg:flex-row items-center justify-center gap-12 mt-12">
          <div className={`${pricing_plan_box_classes} border-gray-400 bg-white dark:bg-neutral-900 dark:border-neutral-800 min-w-[400px]`}>
            <div>
              <div>
                <div className="my-2">
                  <span className="text-4xl font-semibold">$275</span><span className="text-slate-500 dark:text-neutral-500"> / seat / month</span>
                </div>
                <p className="text-slate-500 dark:text-neutral-50">Nullam scelerisque maximus nibh, vitae porttitor augue metus enim</p>
              </div>
              <div className="mt-4">
                <BenefitList plan_id="plan_2" />
              </div>
            </div>
            <div>
              <a href="https://app.accelerlist.com/register/premium" className="btn-md btn-primary block">Try for Free</a>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center gap-8">
            <div>
              <div className="p-1.5 rounded-lg bg-white/10 border dark:border-neutral-50/10 overflow-hidden">
                <div className="bg-[url('./assets/images/kristin-lee.png')] bg-cover bg-no-repeat bg-[center_top_1rem] w-[300px] h-[390px] bg-neutral-200 dark:bg-neutral-900 rounded-lg"></div>
              </div>
            </div>
            <div>
              <blockquote className="text-xl italic opacity-70">"Can't imagine running our management firm without this software. It's streamlined our workflows, boosted our efficiency, and allowed us to scale our business without adding overhead. The ROI is undeniable.  My team loves using it."</blockquote>
              <p className="text-slate-500 dark:text-neutral-500 mt-2">Kristin Lee, KLBM LLC</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const BenefitList = ({ plan_id }) => {
  const li_classes = 'mb-3 flex items-center gap-3 leading-tight text-slate-600 text-[15px] dark:text-neutral-400'
  const list_bullet = (
    <div>
      <svg viewBox="-0.5 -0.5 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" height="16" width="16">
        <path d="M14.535 2.46c-.42-.42-1.05-.42-1.47 0L5.19 10.335 1.935 7.08c-.42-.42-1.05-.42-1.47 0-.42.42-.42 1.05 0 1.47l3.99 3.99c.21.21.42.315.735.315s.525-.105.735-.315l8.61-8.61c.42-.42.42-1.05 0-1.47Z" fill="#08bd05"/>
      </svg>
    </div>
  )

  return (
  <div className="my-8">
    <ul>
      <li className={li_classes}>
        {list_bullet}
        <span>Nulla semper, massa non ullamcorper vehicula</span>
      </li>
      <li className={li_classes}>
        {list_bullet}
        <span>Pellentesque arcu orci, lacinia non venenatis quis, dictum a tellus</span>
      </li>
      <li className={li_classes}>
        {list_bullet}
        <span>Morbi lectus lorem, vestibulum id pulvinar et, porttitor vitae quam</span>
      </li>
      {(plan_id === 'plan_2' || plan_id === 'plan_3') &&
      <>
      <li className={li_classes}>
        {list_bullet}
        <span>Proin elementum, lacus at dignissim auctor, eros velit fermentum</span>
      </li>
      <li className={li_classes}>
        {list_bullet}
        <span>Nullam scelerisque maximus nibh, vitae porttitor augue commodo eu</span>
      </li>
      </>
      }
      {plan_id === 'plan_3' &&
      <li className={li_classes}>
        {list_bullet}
        <span>Ut non lacus nec nibh convallis lacinia quis id odio</span>
      </li>
      }
    </ul>
  </div>
  )
}


export default Pricing